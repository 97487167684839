import React from "react";
import "./Splash.css";

const Splash = ({ hideSplash }) => (
	<div className={`splash ${hideSplash ? "splash-hide" : ""}`}>
		<div className="splash-inner">
			<div className="splash-content">
				<div className="splash-logo">Nordfoto</div>
				<div className="spinner"></div>
			</div>
		</div>
		<div className="splash-version">version 1.7</div>
	</div>
);
export default Splash;
