import React from "react";
import Splash from "../splash/Splash";
import ImageThumb from "../image/imageThumb/ImageThumb";
import ImageLarge from "../image/imageLarge/ImageLarge";
import fetch from "isomorphic-fetch";
import "airbnb-js-shims";
import { HashLink as Link } from "react-router-hash-link";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import "./Main.css";
import { mainMock } from "./mainMock";

const imagesPerRow = 4;

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.unknownKey = 0;
    this.state = {
      images: [],
      newArray: [],
      sections: [],
      preload: [],
      hideSplash: false,
      getUrl: "",
      prevProps: "",
    };
  }

  static contextTypes = {
    router: PropTypes.object,
  };

  componentDidMount() {
    fetch("/api/getMain")
      .then((response) => response.json())
      .then((images) => {
        // Temp fix for building with DS_Store, do not push to production
        // images.shift();
        this.redirectOnInvalidIndex(images);
        this.setState({
          images,
          sections: this.calculateSection(images),
        });
        this.thumbsLoaded = 0;
      });

    document.addEventListener("scroll", this.handleScroll, true);
    ReactGA.initialize("UA-1173671-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  requestNewArray = (prevProps, idx) => {
    if (
      this.props.newArray !== prevProps.newArray &&
      this.props.newArray !== false
    ) {
      let cloneArray = [...mainMock];
      if (this.props.newArray !== "all") {
        for (let i = idx.length - 1; i >= 0; i--) cloneArray.splice(idx[i], 1);
      }
      this.redirectOnInvalidIndex(cloneArray);
      this.setState({
        images: cloneArray,
        sections: this.calculateSection(cloneArray),
      });
      this.thumbsLoaded = 0;
    }
  };

  componentDidUpdate(prevProps) {
    if (window.location.pathname === "/") {
      document.querySelector("html").classList.remove("prevent-scroll");
      document.ontouchmove = function (e) {
        return true;
      };
    }
    if (this.props.newArray === "all") {
      this.requestNewArray(prevProps);
    } else if (this.props.newArray === "street") {
      const removeFromIndex = [
        0, 2, 4, 5, 10, 12, 13, 15, 18, 20, 21, 24, 25, 26, 30, 33, 34, 38, 39,
        44, 49, 50, 54, 55, 56, 59, 61, 62, 63, 66, 67, 68, 69, 70,
      ];
      this.requestNewArray(prevProps, removeFromIndex);
    } else if (this.props.newArray === "nature") {
      const removeFromIndex = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70];
      this.requestNewArray(prevProps, removeFromIndex);
    } else if (this.props.newArray === "travel") {
      const removeFromIndex = [];
      this.requestNewArray(prevProps, removeFromIndex);
    }
    if (this.props.newArray !== prevProps.newArray) {
      this.setState({
        prevProps: prevProps,
      });
    }

    ReactGA.initialize("UA-1173671-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  redirectOnInvalidIndex = (images) => {
    const { selectedImageIndex } = this.props;
    const { length } = images;
    if (
      !(length > 0 && selectedImageIndex > 0 && selectedImageIndex <= length)
    ) {
      this.props.history.push("/");
    }
  };

  calculateSection = (imageData) => {
    const rows = [];
    const { length } = imageData;
    let row = [];
    imageData.forEach((elm, idx) => {
      row.push(idx);

      if (idx === length - 1) {
        const lastRowLength = row.length;
        row.length = imagesPerRow;
        row.fill(-1, lastRowLength);
      }

      if (row.length === imagesPerRow) {
        rows.push(row);
        row = [];
      }
    });
    return rows;
  };

  getImage = (idx) => {
    const imageData = this.state.images[idx - 1] || {};
    return (
      <div className="image-large">
        <div className="image-large-wrapper">
          <ImageLarge
            exif={imageData.exifData}
            src={imageData.main}
            close={this.closeImage}
            next={this.next}
            prev={this.prev}
            firstImage={this.firstImage}
            lastImage={this.lastImage}
            isFirst={idx === 1}
            isLast={idx === this.state.images.length}
            countLast={this.state.images.length}
            countCurrent={this.props.selectedImageIndex}
            hideSplash={this.state.hideSplash}
            getUrl={this.state.getUrl}
            alt=""
          />
        </div>
      </div>
    );
  };

  thumbLoaded = () => {
    if (this.state.images.length === ++this.thumbsLoaded) {
      this.setState({
        hideSplash: true,
        preload: this.state.images.map((image) => image.main),
        getUrl: window.location.pathname,
      });
    }
  };

  getThumbnails = () => {
    return this.state.sections.map((row, idx) => (
      <div className="thumb-section" key={idx}>
        {row.map(this.getThumbnail)}
      </div>
    ));
  };

  getThumbnail = (idx) => {
    const imageData = this.state.images[idx] || {};
    return (
      <div className="thumb-image" key={idx > -1 ? idx : this.getUnknownKey()}>
        <Link to={`/index/${idx + 1}`}>
          <ImageThumb
            src={imageData.thumbLarge}
            onLoad={this.thumbLoaded}
            alt=""
          />
        </Link>
      </div>
    );
  };

  getUnknownKey = () => {
    return this.unknownKey++ % imagesPerRow;
  };

  next = () => {
    const newIndex = this.props.selectedImageIndex % this.state.images.length;
    this.props.history.push(`/index/${newIndex + 1}`);
  };

  prev = () => {
    const lastImageIndex = this.state.images.length;
    const { selectedImageIndex } = this.props;
    const newIndex =
      selectedImageIndex === 1 ? lastImageIndex : selectedImageIndex - 1;

    this.props.history.push(`/index/${newIndex}`);
  };

  lastImage = () => {
    const lastImageIndex = this.state.images.length;
    this.props.history.push(`/index/${lastImageIndex}`);
  };

  firstImage = () => {
    this.props.history.push(`/index/1`);
  };

  closeImage = () => {
    document.querySelector("html").classList.remove("prevent-scroll");
    document.ontouchmove = function (e) {
      return true;
    };
    document.querySelector(".image-large").classList.add("image-large-fade");
    setTimeout(() => {
      this.props.history.push("/");
    }, 100);
  };

  handleScroll = () => {
    const getScrollElement = document.querySelector("#root");
    const getScrollTopElement = document.querySelector(".scroll-top");

    if (typeof getScrollElement != "undefined" && getScrollElement != null) {
      if (getScrollElement.getBoundingClientRect().top < -175) {
        getScrollTopElement.classList.add("scroll-top-show");
        getScrollTopElement.classList.remove("scroll-top-hide");
      } else {
        getScrollTopElement.classList.add("scroll-top-hide");
        getScrollTopElement.classList.remove("scroll-top-show");
      }
    }
  };

  removeHash = () => {
    if (window.location.pathname === "/") {
      window.history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
    }
  };

  getPreloadImages = () => {
    return (
      <Helmet>
        {this.state.preload.map((url, idx) => (
          <link rel="preload" href={url} key={idx} as="image" />
        ))}
      </Helmet>
    );
  };

  render() {
    const { selectedImageIndex } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <meta
            property="og:title"
            content="Nordfoto - photos taken by Jens Nordström"
          />
          <meta
            property="og:description"
            content="Here is a collection of the best photos that I have taken over the years, with more to come!"
          />
          <meta
            property="og:image"
            content="https://www.nordfoto.se/images/main/22.JPG"
          />
        </Helmet>
        <Splash hideSplash={this.state.hideSplash} />
        <div className="top-anchor" id="top" />
        <div
          className={`main ${
            this.props.menuState === true ? "prevent-click" : "allow-click"
          }`}
        >
          <div className="thumb-grid">
            {this.state.images.length > 0 &&
              !isNaN(selectedImageIndex) &&
              this.getImage(selectedImageIndex)}
            {this.getThumbnails()}
          </div>
        </div>
        <Link
          className="scroll-top scroll-top-hide"
          to="#top"
          onClick={this.removeHash()}
          scroll={(el) =>
            el.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }
        >
          <i className="fas fa-arrow-up"></i>
        </Link>
        {this.getPreloadImages()}
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
