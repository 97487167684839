import React from "react";
import "./Footer.css";

const Footer = () => {
	const newDate = new Date().getFullYear();
	return (
		<div className="footer">
			nordfoto.se {newDate} © &nbsp;/ version 1.7
			<span className="footer-mobile">
				<a href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
					&nbsp; / made with <i className="fas fa-atom" />
				</a>
				<a
					href="http://www.react.express/"
					target="_blank"
					rel="noopener noreferrer"
				>
					-
					<i className="fas fa-shipping-fast" />
				</a>
				<a
					href="https://fontawesome.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					-
					<i className="fab fa-fort-awesome" />
				</a>
			</span>
		</div>
	);
};

export default Footer;
