import React from "react";
import Cookies from "universal-cookie";
import { reactLocalStorage } from "reactjs-localstorage";

class MobileControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      togglePlay: false,
      closeClicked: false,
    };
  }

  componentDidMount() {
    reactLocalStorage.set("activeTutorial", true);
  }

  componentWillUnmount() {
    this.setState({
      togglePlay: false,
    });
    clearInterval(this.startInterval);
  }

  clearInterval() {
    clearInterval(this.startInterval);
    this.setState({
      togglePlay: false,
      intervalButtons: false,
    });
  }

  play = () => {
    const cookies = new Cookies();
    const currentState = this.state.togglePlay;
    cookies.set("disabledTutorial", "cookie", { path: "/", maxAge: "86400" });
    this.setState({
      togglePlay: !currentState,
      intervalButtons: this.state.togglePlay ? false : true,
    });

    if (this.state.togglePlay === true) {
      clearInterval(this.startInterval);
      return;
    }

    let isIntervalInProgress = false;
    this.startInterval = setInterval(() => {
      if (isIntervalInProgress) return false;

      isIntervalInProgress = true;

      this.props.next();

      isIntervalInProgress = false;
    }, 5000);
  };

  mobileTutorial = () => {
    const cookies = new Cookies();
    if (this.state.closeClicked) {
      cookies.set("disabledTutorial", "cookie", { path: "/", maxAge: "86400" });
      return false;
    } else if (cookies.get("disabledTutorial") === "cookie") {
      return false;
    } else if (
      reactLocalStorage.getObject("activeTutorial") === true &&
      this.props.hideSplash === true
    ) {
      return true;
    }
  };

  render() {
    const { close, countCurrent, countLast, hideSplash, getUrl } = this.props;

    // const { togglePlay } = this.state;
    const cookies = new Cookies();

    if (this.state.closeClicked) {
      cookies.set("disabledTutorial", "cookie", { path: "/", maxAge: "86400" });
    }
    return (
      <React.Fragment>
        <div className="image-mobile-controls">
        <div className="image-mobile-controls-right">
            {/*             <div className="image-mobile-play" onClick={() => this.play()}>
              <i
                className={
                  togglePlay === true ? "fas fa-stop" : "far fa-play-circle"
                }
              />
            </div> */}
          </div>
          <div className="image-mobile-count">
            {countCurrent} / {countLast}
          </div>
          <div
            className="image-mobile-close"
            onClick={() => {
              close();
              this.setState({
                closeClicked: true,
              });
            }}
          >
            <i className="far fa-times-circle" />
          </div>
        </div>
        <div
          className={`${
            this.mobileTutorial() ? "show-tutorial" : "hide-tutorial"
          }`}
        >
          <div
            className={`image-mobile-tut-overlay ${
              hideSplash && getUrl === "/"
                ? "tut-fade-out-instant"
                : "tut-fade-out"
            }`}
          ></div>
        </div>
        <div
          className={`${
            this.mobileTutorial() ? "show-tutorial" : "hide-tutorial"
          }`}
        >
          <div
            className={`image-mobile-tut ${
              hideSplash && getUrl === "/"
                ? "tut-fade-out-instant"
                : "tut-fade-out"
            }`}
          >
            <div className="image-mobile-tut-controll">
              <i
                className={`fas fa-chevron-left ${
                  hideSplash && getUrl === "/"
                    ? "chevron-animate-one-instant"
                    : "chevron-animate-one"
                }`}
              ></i>
            </div>
            <div className="image-mobile-tut-divider"></div>
            <div className="image-mobile-tut-controll">
              <i
                className={`fas fa-chevron-right ${
                  hideSplash && getUrl === "/"
                    ? "chevron-animate-two-instant"
                    : "chevron-animate-two"
                }`}
              ></i>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MobileControls;
