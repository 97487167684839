import React from "react";
import Cookies from "universal-cookie";
import { reactLocalStorage } from "reactjs-localstorage";

class Controls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      togglePlay: false,
      buttonFive: true,
      buttonTen: false,
      intervalButtons: false,
    };
  }

  componentDidMount() {
    reactLocalStorage.set("activeTutorial", true);
  }

  componentWillUnmount() {
    this.setState({
      togglePlay: false,
    });
    clearInterval(this.startInterval);
  }

  clearInterval() {
    clearInterval(this.startInterval);
    this.setState({
      togglePlay: false,
      intervalButtons: false,
    });
  }

  play = () => {
    const cookies = new Cookies();
    const currentState = this.state.togglePlay;
    cookies.set("disabledTutorial", "cookie", { path: "/", maxAge: "86400" });
    this.setState({
      togglePlay: !currentState,
      intervalButtons: this.state.togglePlay ? false : true,
    });

    if (this.state.togglePlay === true) {
      clearInterval(this.startInterval);
      return;
    }

    let isIntervalInProgress = false;
    this.startInterval = setInterval(() => {
      if (isIntervalInProgress) return false;

      isIntervalInProgress = true;

      this.props.next();

      isIntervalInProgress = false;
    }, 10000);
  };

  RePlayInterval = (millisec) => {
    clearInterval(this.startInterval);
    let isIntervalInProgress = false;
    this.startInterval = setInterval(() => {
      if (isIntervalInProgress) return false;

      isIntervalInProgress = true;

      this.props.next();

      isIntervalInProgress = false;
    }, millisec);
  };

  triggerButtonFive = () => {
    this.setState({
      buttonFive: true,
      buttonTen: false,
    });
    this.RePlayInterval(10000);
  };

  triggerButtonTen = () => {
    this.setState({
      buttonFive: false,
      buttonTen: true,
    });
    this.RePlayInterval(60000);
  };

  showTimer = () => {
    if (this.state.togglePlay === true && this.state.buttonFive === true) {
      return (
        <div className="image-overlay-timer">
          <div className="progress-moved progress-five">
            <div className="progress-bar" />
          </div>
          <div />
        </div>
      );
    } else if (
      this.state.togglePlay === true &&
      this.state.buttonTen === true
    ) {
      return (
        <div className="image-overlay-timer">
          <div className="progress-moved progress-ten">
            <div className="progress-bar" />
          </div>
          <div />
        </div>
      );
    } else {
      return;
    }
  };

  render() {
    const { next, prev, close, isFirst, isLast, firstImage, lastImage } =
      this.props;

    const { togglePlay, intervalButtons, buttonFive, buttonTen } = this.state;

    window.onkeydown = function( event ) {
      if (event.keyCode === 27) {
          close();
      } else if (event.keyCode === 39) {
        next();
      } else if (event.keyCode === 37) {
        prev();
      }
  };

    return (
      <React.Fragment>
        <div
          className={`image-overlay-next-outer ${
            togglePlay === true ? "image-controls-hide" : "image-controls-show"
          }`}
          onClick={() => {
            this.clearInterval();
            next();
          }}
        />

        <div
          className={`image-overlay-prev-outer ${
            togglePlay === true ? "image-controls-hide" : "image-controls-show"
          }`}
          onClick={() => {
            this.clearInterval();
            prev();
          }}
        />
        <div className="image-overlay-inner">
          <div
            className={`image-overlay-next ${
              togglePlay === true
                ? "image-controls-hide"
                : "image-controls-show"
            }`}
            onClick={() => {
              this.clearInterval();
              next();
            }}
          >
            <i className="fas fa-caret-right" />
          </div>

          <div
            className={`image-overlay-prev ${
              togglePlay === true
                ? "image-controls-hide"
                : "image-controls-show"
            }`}
            onClick={() => {
              this.clearInterval();
              prev();
            }}
          >
            <i className="fas fa-caret-left" />
          </div>

          <div className="image-overlay-controls">
            <div className="image-overlay-controls-inner">
              <div>
                <div className="image-overlay-controls-buttons">
                  <div
                    className={`image-overlay-controls-seconds-wrapper ${
                      intervalButtons === true
                        ? "show-interval"
                        : "hide-interval"
                    }`}
                  >
                    <div
                      className={`image-overlay-controls-seconds ${
                        buttonFive ? "image-overlay-controls-timer-active" : ""
                      }`}
                      onClick={() => this.triggerButtonFive()}
                    >
                      10 seconds
                    </div>
                    <div
                      className={`image-overlay-controls-seconds ${
                        buttonTen ? "image-overlay-controls-timer-active" : ""
                      }`}
                      onClick={() => this.triggerButtonTen()}
                    >
                      60 seconds
                    </div>
                  </div>
                  <div className="image-overlay-controls-wrapper">
                    <div
                      className={`image-overlay-backwards ${
                        isFirst ? "image-overlay-hide" : ""
                      }`}
                      onClick={() => {
                        this.clearInterval();
                        firstImage();
                      }}
                    >
                      <i className="fas fa-fast-backward" />
                    </div>

                    <div
                      className="image-overlay-play"
                      onClick={() => this.play()}
                    >
                      <i
                        className={
                          togglePlay === true ? "fas fa-stop" : "fas fa-play"
                        }
                      />
                    </div>

                    <div
                      className="image-overlay-forward"
                      onClick={() => {
                        this.clearInterval();
                        lastImage();
                      }}
                    >
                      <i
                        className={`fas fa-fast-forward ${
                          isLast ? "image-overlay-hide" : ""
                        }`}
                      />
                    </div>
                  </div>
                </div>
                <div className="image-overlay-controls-close">
                  <i className="far fa-times-circle" onClick={close} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.showTimer()}
      </React.Fragment>
    );
  }
}

export default Controls;
