import React from "react";
import Controls from "./controls";
import MobileControls from "./mobileControls";
import Exif from "./exif";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import "../Image.css";

class ImageLarge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: {},
      showControls: "image-overlay-hide",
      imageFadeIn: "image-fade-hide",
    };
  }

  componentDidMount() {
    document.querySelector("html").classList.add("prevent-scroll");
    document.ontouchmove = function (e) {
      e.preventDefault();
    };
    ReactGA.initialize("UA-1173671-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidUpdate(prevProps) {
    document.ontouchmove = function (e) {
      e.preventDefault();
    };
    if (prevProps.src !== this.props.src) {
      this.setState({
        showControls: "image-overlay-hide",
        imageFadeIn: "image-fade-hide",
      });
    }
    ReactGA.initialize("UA-1173671-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  setDimensionClass = ({ target: img }) => {
    this.setState({
      dimensions: { height: img.offsetHeight, width: img.offsetWidth },
      showControls: "image-overlay",
      imageFadeIn: "image-fade",
    });
  };

  render() {
    const { width, height } = this.state.dimensions;
    const { src } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <meta
            property="og:title"
            content="Nordfoto - photos taken by Jens Nordström"
          />
          <meta
            property="og:description"
            content="Here is a collection of the best photos that I have taken over the years, with more to come!"
          />
          <meta
            property="og:image"
            content={`https://www.nordfoto.se/${src}`}
          />
        </Helmet>
        <div className={this.state.imageFadeIn}>
          <img
            className={width > height ? "landscape-large" : "portrait-large"}
            onLoad={this.setDimensionClass}
            src={src}
            alt=""
          />
        </div>
        <MobileControls {...this.props} />
        <div className={this.state.showControls}>
          <Controls {...this.props} />
          <Exif {...this.props} />
        </div>
      </React.Fragment>
    );
  }
}

export default ImageLarge;
