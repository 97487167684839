import React from "react";

class Exif extends React.Component {
	convertCameraName = () => {
		const { exif } = this.props;
		switch (exif.image.Model) {
			case "Canon EOS 350D DIGITAL":
				return "Canon 350D";
			case "Canon EOS 5D":
				return "Canon 5D Mark I";
			case "ILCE-7M2":
				return "Sony A7 II";
			case "Canon EOS 5D Mark IV":
				return "Canon 5D Mark IV";
			default:
				return "Canon IXY 600";
		}
	};

	convertExposure = () => {
		const { exif } = this.props;
		const truncateValue = 1 / exif.exif.ExposureTime;

		if (truncateValue % 1 !== 0) {
			return exif.exif.ExposureTime + " / 1";
		} else {
			return "1 / " + truncateValue;
		}
	};

	render() {
		const { exif, countCurrent, countLast } = this.props;
		return (
			<div className="image-overlay-exif-wrapper">
				<div>
					<div className="image-overlay-exif">
						<div className="image-overlay-exif-inner">
							<div className="image-overlay-exif-inner-exif">
								<div>Camera: {this.convertCameraName()}</div>
								<div>Exposure: {this.convertExposure()}</div>
								<div>F-stop: {exif.exif.FNumber}</div>
								<div>ISO: {exif.exif.ISO ? exif.exif.ISO : "No data"}</div>
								<div>Focal length: {exif.exif.FocalLength} mm</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div className="image-overlay-count">
						{countCurrent} / {countLast}
					</div>
				</div>
			</div>
		);
	}
}

export default Exif;
