import React from "react";
import "../Image.css";

class ImageThumb extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dimensions: {},
		};
	}

	setDimensionClass = ({ target: img }) => {
		this.setState({
			dimensions: { height: img.offsetHeight, width: img.offsetWidth },
		});
		this.props.onLoad();
	};

	render() {
		const { src } = this.props;
		const { width, height } = this.state.dimensions;
		return (
			<img
				className={width > height ? "thumb-landscape" : "thumb-portrait"}
				onLoad={this.setDimensionClass}
				src={src}
				alt=""
				onClick={this.props.onClick}
			/>
		);
	}
}

export default ImageThumb;
