import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Index from "./components/index/Index";

function App() {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/" component={Index} />
				<Route path="/index/:selectedImageIndex" component={Index} />
			</Switch>
		</BrowserRouter>
	);
}

export default App;
